const booking = {
  title: 'Đặt hẹn',
  bookingSuccess: 'Đặt hẹn thành công!',
  phone: 'Số điện thoại',
  email: 'Email',
  fullName: 'Họ tên',
  date: 'Thời gian',
  dayComing: 'Ngày đến',
  timeComing: 'Thời gian đến',
  service: 'Dịch vụ',
  services: 'Dịch vụ',
  technicians: 'Kỹ thuật viên',
  optional: 'không bắt buộc',
  note: 'Chú ý',
  bookAnAppointment: 'Đặt hẹn',
  selectService: 'Chọn dịch vụ',
  noServiceYet: 'Chưa có dịch vụ',
  addService: 'Thêm dịch vụ',
  emptySalon: 'KHÔNG TÌM THẤY TIỆM!',
  emptySalonDescription: 'Vui lòng liên hệ tiệm để lấy đường dẫn đặt hẹn.',
  msgInvalidEmail: 'Email không đúng định dạng',
  msgInvalidPhone: 'Số điện thoại không đúng định dạng',
  selectServices: 'Chọn dịch vụ',
  closed: 'Đóng cửa',
  createAnAccount: 'Tạo tài khoản',
  alreadyAnAccount: 'Đã có tài khoản?',
  loginNow: 'Đăng nhập ngay',
  referencePriceNote: 'Giá chỉ mang tính chất tham khảo',

  backendErrors: {
    captchaInvalid: ' Captcha không đúng. Vui lòng tải lại trang.',
    youBlocked: 'Bạn đã bị chặn truy cập.',
    youAreBot: 'Bạn là bot.',
    nameRequired: 'Yêu cầu tên.',
    phoneRequired: 'Yêu cầu số điện thoại.',
    salonRequired: 'Yêu cầu chọn 1 tiệm.',
    startDateTimeRequired: 'Yêu cầu thời gian check in.',
    typeRequired: 'Yêu cầu loại đặt hẹn.',
    paxNumberRequired: 'Yêu cầu nhập số lượng khách.',
  }
}

export default booking