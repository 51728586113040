import { createUseStyles } from 'react-jss'

const useStyles: any = createUseStyles({
  modal: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    maxHeight: '80vh',
    width: '700px',
    '@media (max-width: 768px)': {
      width: '400px',
    },
    '@media (max-width: 480px)': {
      width: '320px',
    },
  },

  // technician Modal List
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px 20px',
    fontWeight: '700',
    color: '#2245a9',
    borderBottom: '1px solid #2245a9',
    fontSize: '20px',
    marginBottom: '20px',
  },

  modalFooter: {
    padding: '15px 20px',
    borderTop: '1px solid #2245a9',
    marginTop: '20px',
  },

  txtRefPriceNote: {
    textAlign: 'right',
    paddingBottom: '10px',
    fontStyle: 'italic',
    fontSize: '13px',
    color: '#E12828'
  },

  modalButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  modalButton: {
    display: 'flex',
    padding: '8px 12px',
    fontWeight: '700',
    borderRadius: '10px',
    color: '#fff',
    backgroundColor: '#2245a9',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    marginLeft: '15px',
  },

  modalButtonClose: {
    display: 'flex',
    padding: '8px 12px',
    fontWeight: '700',
    borderRadius: '10px',
    color: '#2245a9',
    backgroundColor: '#fff',
    border: '1px solid #2245a9',
    outline: 'none',
    cursor: 'pointer',
    fontSize: '14px',
  },

  bookingInput: {
    height: '44px',
    background: '#F3F6F6',
    border: '1px solid #DFDFDF',
    boxSizing: 'border-box',
    borderRadius: '8px',
    width: '100%',
    marginBottom: '20px',
    padding: '15px 10px'
  },
})

export default useStyles
